<script>
import { onMounted, watch, onUnmounted, ref, reactive, inject } from 'vue'
import QRCode from 'qrcodejs2'
import { upload } from 'qiniu-js'
import copy from 'copy-text-to-clipboard'
import qiniuUpload from '@/utils/qiniu-upload'
import { http } from '@/http'
import { Toast } from 'vant'
import wx from '@/utils/weixin'
import { uuid, dataURLtoFile } from '@/utils'
import { needLoading } from '@/reactive'

export default {
  setup() {
    let qrcodeUrl = ref('')
    let qrcodeRef = ref(null)
    let qrcode = null
    const catagoryShow = ref(false)
    const catagory = ref('')

    function makeQRCode() {
      needLoading.value = true
      return http.post(
        '/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.getQrcodeUrl'
      ).then((res) => {
        if (res.result) {
          qrcodeUrl.value = res.result
        }
      })
    }

    function handleCopy() {
      if(!qrcodeUrl.value) return
      copy(qrcodeUrl.value)
      Toast.success('推广链接复制成功')
    }

    function getMallCatagory() {
      http.post(
        '/?cmd=com.xqxc.api.pigear.biz.platform.KefuTelService.getMallCatagory'
      ).then(res => {
        if(res.result){
          catagory.value = res.result
          catagoryShow.value = true
        }else{
          catagoryShow.value = false
        }
      })
    }

    onMounted(() => {
      qrcode = document.getElementById('qrcode')
      getMallCatagory()
      makeQRCode().then(() => {
        try {
          qrcode.innerHTML = ''
          qrcodeRef.value = new QRCode(qrcode, {
            text: qrcodeUrl.value,
            width: 180,
            height:180
          })        

          let canvas = document.querySelectorAll('canvas')

          if(canvas[0]) {
            let files = dataURLtoFile(canvas[0].toDataURL(), uuid())
            qiniuUpload(files, {
              errorCallback() {
              },
              completeCallback(res) {
                let img = document.createElement('img')
                img.setAttribute('src', res.result) 
                qrcode.appendChild(img)
                // 搞不懂微信的规则。。。
                qrcode.style.visibility = 'visible'
                if(document.querySelectorAll('img')[1]){
                  document.querySelectorAll('img')[1].crossOrigin = 'anonymous'
                  document.querySelectorAll('img')[1].style.cssText = 'z-index:1000; opacity:1'
                }
                
              }
            })
          }
        } catch(e) {
          // console.log(e)
        }
      
      })
    })

    watch(() => qrcodeUrl.value, (link, prevLink) => {
      // let { id } = JSON.parse(window.localStorage.getItem('userAuth'))
      wx.setup({
        shareUrl: window.location.origin+'/#/signup',
        imgUrl: window.location.origin + '/icon500.png',
        title: '猪耳朵商户注册',
        desc: '猪耳朵省时、省力、更省钱，聚合平台领跑者',
        link
      })
    })
    return {
      handleCopy,
      catagoryShow,
      catagory
    }
  }
}
</script>
<template>
  <div class="page">
    <div class="r-box" style="margin-top:13px">
      <h4 class="s-title">猪耳朵商户平台欢迎你的注册</h4>
      <p style="font-size:.26rem; color: #666; margin-bottom: 1.28rem; text-align:center">猪耳朵省时、省力、更省钱，聚合平台领导者</p>
      <div class="qrcode" id="qrcode" style="visibility: hidden"></div>
      <p style="font-size: 0.26rem; margin: .4rem 0; text-align: center">长按二维码保存至手机</p>
      <div class="des" v-if="catagoryShow">当前可推广商城类目为 “{{catagory}}” 的商户</div>
    </div>
    <div style="margin: 20px 13px 0;" id="ppk">
      <van-button block type="primary" @click="handleCopy">点击复制推广链接</van-button>
    </div>
  </div>
</template>
<style scoped>
.s-title { margin: 1.09rem 0 .55rem; font-size:.32rem; font-weight:bold; text-align:center; }
.qrcode {
  position: relative;
  margin: 0.6rem auto;
  width: 4rem;
  height: 4rem;
  user-select: none;
}
/deep/ .qrcode img{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity:  0;
}
.des{
  text-align: center;
  margin: 1rem 0 0.2rem;
}

</style>